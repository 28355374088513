.chart {
  flex: 4;
  height: 17em;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  color: gray;

  .title {
    margin-bottom: 20px;
  }

  .editButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 12px;
    color: #7451f8;
    background-color: #7551f818;
    cursor: pointer;
    border-radius: 0px 0px 0px 5px;
  }

  .chartGrid {
    stroke: rgb(228, 225, 225);
  }
}
