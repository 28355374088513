.home {
  display: flex;

  .homeContainer {
    flex: 6;

   .composeContainer{
    height: 88vh;
    margin: 20px;
    border-radius: 10px;
    background-color: aliceblue;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    .compose-formContainer{
      margin: 20px;
      .text-mand{
        font-size: 10px;
        color:crimson
      }
      .compose-textArea{
        height: 30vh;
        margin-bottom: 2px;
        margin-top: 2px;

      }
      .compose-mail{
        height: 8vh;
        margin-bottom: 2px;
        margin-top: 2px;
      }
      .compose-name{
        height: 10vh;
        margin-bottom: 2px;
        margin-top: 2px;
      }
    }

    .composeFooter{
      height: 5em;
      border-radius: 0px 0px 10px 10px;
      background-color:#343644;

     .text-right{
      .default-btn{
        background-color: #6d8bf7;
        height: 40px;
        margin: 20px 20px 10px 10px;
      }
     } 
    }
    
   } 
  }
}
