
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.become-tester{
  height: auto !important;
  
}
.become-tester p{
  color: #282c34;
}
.tester-bullet li{
  font-size: 18px;
  padding: 5px 0px;

}
.become-tester .single-services-box:hover::before{
  background-color: #f4f7fc !important;
  
}
.become-tester .single-services-box:hover a, .become-tester .single-services-box:hover p{
  color: #282c34  !important;
}

.tester-bullet li i{
  font-size: 18px;
  color: #ff5d00;
}
.become-tester .default-btn{
  background-color: #ff5d00;
  margin-left: 40px;
}

.privacy-policy h4{
  font-size: 25px;
  color: #ff5d00;
  margin: 15px 0px;

}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.video-content li{
  font-size: 15px;
  margin-bottom: 10px;
}
.blog-single br{
  display: none;
}
.blog-single strong,.blog-single b{
  color: #282c34;

}
.CookieConsent{
  text-align: center;
}
.single-blog p{
  font-size: 15px;
  color: #545252 !important;
}
#rcc-decline-button,#rcc-confirm-button{
  background: #00a99d !important;
  margin-left: 0px !important;
  color: #fff !important;
  border-radius: 3px !important;
  margin: 7px 15px !important;
}
#hubspot-messages-iframe-container.widget-align-right{
  bottom: 50px !important;
}
.blog-single ul{
  padding: 0 12px;
  list-style: none;
  position: relative;

}
.blog-single a{
  color: #0000ff
  /* #0000ff; */
}

.box-auto:hover .dropt-button{
  background-color: #fff;
  color: #00f;
  padding: 5px 10px 0px;
  border-radius: 5px;
}
.blog-single ul li::before{
  background: #00f;
  /* #00f; */
    position: absolute;
    height: 7px;
    width: 7px;
    content: '';
    border-radius: 20px;
    left: 0;
    top: 6px;
}

.spinner {
  height: 4rem; /* Equivalent to h-16 */
  width: 4rem; /* Equivalent to w-16 */
  animation: spin 2s linear infinite; /* Equivalent to animate-spin */
  border-radius: 9999px; /* Equivalent to rounded-full */
  border-width: 0.5rem; /* Equivalent to border-4 */
  border-style: solid; /* Equivalent to border-solid */
  border-color: #ff5d00; /* Equivalent to border-primary */
  border-top-color: transparent; /* Equivalent to border-t-transparent */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.video-content ul{
  list-style: none !important;
  padding: 0;
}
.App-link {
  color: #61dafb;
}
.white-bg {
  background-color: #fff;
}
.btn-radius{
  border-radius: 25px;
}
.gray-bg1 {
  background-color: #f0f0f570;
}
.black-bg{
  background-color: #282c34;
  color: #fff;
}
.display-flex{
  display: flex;
}
.service-box .display-flex{
  border-bottom: 1px solid #524949;
}
.service-box{
  width: 100%;
  float: left;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  align-items: center;
  background-color: #f8faff;
    border: 1px solid #524949;
    margin-bottom: 15px;
  -webkit-box-shadow: 0px 0px 13px -6px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 0px 13px -6px rgba(0, 0, 0, 0.25);
}
.service-box .display-flex div:first-child{
  width: 80%;
}
.service-box .display-flex div:last-child{
  width: 20%;
}

.service-box img{
  float: right;
  width: 50px;
  margin-top: -15px;
}
.service-box p{
  font-size: 14px;
  padding-top: 5px;
}
.hidden{
  display: none;
}

.border-dashed, .single-fun-facts{
  border:1px dashed #f8faff;
}
.hover-content{
  margin-top: 10px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

.testdel-color{
  background-color: #ff7a59;
  border-color: #ff7a59;
  color:white;
}
.form-control{
  border-radius: 5px;
}